/* eslint-disable */
import React from "react";
import Image from "next/image";
import makeStyles from "@mui/styles/makeStyles";
// import Icon from "@mui/material/Icon";
// @mui/icons-material
// import Build from "@mui/icons-material/Build";
// import Subject from "@mui/icons-material/Subject";
// import FormatPaint from "@mui/icons-material/FormatPaint";
// import Code from "@mui/icons-material/Code";
// import Dashboard from "@mui/icons-material/Dashboard";
// import Timeline from "@mui/icons-material/Timeline";
// import Group from "@mui/icons-material/Group";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// import Button from "components/CustomButtons/Button.js";
// import Muted from "components/Typography/Muted.js";
// import InfoArea from "components/InfoArea/InfoArea.js";
// import Badge from "components/Badge/Badge.js";

// @mui/icons-material
// import FormatAlignLeft from "@mui/icons-material/FormatAlignLeft";
// import Favorite from "@mui/icons-material/Favorite";

import projectsStyle from "styles/jss/nextjs-material-kit-pro/pages/sectionsSections/projectsStyle.js";

const useStyles = makeStyles(projectsStyle);

export default function SectionProjects({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Project 2 START */}
      <div className={classes.projects}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>Some of our case studies 📚</h2>
            </GridItem>
          </GridContainer>
          <GridContainer style={{ padding: "0px" }}>
          <GridItem xs={12} sm={3} md={3}>
              <Card>
                <a href="/our-work/streamlit-and-business-performance">
                  <CardHeader image plain noShadow>
                    <Image
                      layout="responsive"
                      height={381}
                      width={540}
                      src={"/img/sections/services/streamlit/streamlit_login.png"}
                      alt="..."
                    />
                  </CardHeader>

                  <CardBody plain>
                    <h4 className={classes.cardTitle}>
                      Case Study 1: Streamlit & Bizcap - Business Performance Dashboard
                    </h4>

                    <p className={classes.description}>
                    In this study case we showcase a detailed and fast Streamlit application designed for Bizcap's business...{" "}
                    </p>
                  </CardBody>
                </a>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <Card>
                <a href="/our-work/bizcap">
                  <CardHeader image plain noShadow>
                    <Image
                      layout="responsive"
                      height={381}
                      width={540}
                      src={"/img/sections/bizcap-wordpress.jpg"}
                      alt="..."
                    />
                  </CardHeader>

                  <CardBody plain>
                    <h4 className={classes.cardTitle}>
                      Case Study 2: Website Redesign & Marketing Ramp-up for
                      Bizcap
                    </h4>

                    <p className={classes.description}>
                      Bizcap are a growing financial services business based in
                      Australia and with offices in New Zealand{" "}
                    </p>
                  </CardBody>
                </a>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <Card>
                <a href="/our-work/streamlit-and-google-analytics-data">
                  <CardHeader image plain noShadow>
                    <Image
                      layout="responsive"
                      height={381}
                      width={540}
                      src="/img/sections/services/streamlit/streamlit_and_ga.png"
                      alt="..."
                    />
                  </CardHeader>

                  <CardBody plain>
                    <h4 className={classes.cardTitle}>
                      Case Study 3: Streamlit & David Jones - Google Analytics Data
                    </h4>

                    <p className={classes.description}>
                    In this case study we showcase a few Streamlit applications that we made for David Jones in order to help..
                    </p>
                  </CardBody>
                </a>
              </Card>
            </GridItem>
            
            <GridItem xs={12} sm={3} md={3}>
              <Card>
                <a href="/our-work/afl">
                  <CardHeader image plain noShadow>
                    <Image
                      layout="responsive"
                      height={381}
                      width={540}
                      src="/img/sections/AFL-sheets.jpg"
                      alt="..."
                    />
                  </CardHeader>

                  <CardBody plain>
                    <h4 className={classes.cardTitle}>
                      Case Study 4: Data Collection AFL Statewide Programs
                    </h4>

                    <p className={classes.description}>
                      The AFL is trying to grow the game across NSW running
                      programs in schools and clubs around the state
                    </p>
                  </CardBody>
                </a>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Project 2 END */}
    </div>
  );
}
