import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Typography, Container } from "@mui/material";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useRouter } from 'next/router';
import CardExampleCardRotating from "components/RotatingCard/RotatingCardSmall.js";

const useStyles = makeStyles((theme) => ({
    section: {
        padding: "70px 0",
        background: "linear-gradient(180deg, #f8f9fa 0%, #ffffff 100%)",
    },
    title: {
        color: "#3C4858",
        margin: "1.75rem 0 0.875rem",
        textDecoration: "none",
        fontWeight: "700",
        fontFamily: `"Roboto Slab", "Times New Roman", serif`,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        fontSize: "2.25rem"
    },
    subtitle: {
        fontSize: "1.063rem",
        lineHeight: "1.5em",
        marginBottom: "20px",
        textAlign: "left",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "300",
        color: "#666",
    },
    emphasis: {
        color: "#3C4858",
        fontWeight: "700",
        marginBottom: "1rem",
        marginTop: "30px",
        textAlign: "left",
        fontSize: "1.25rem",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    cardContainer: {
        display: "grid",
        gap: "30px",
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        padding: "20px 0",
    },
    clickableCard: {
        cursor: "pointer",
        height: "100%", // Ensure full height
        transition: "transform 0.2s ease",
            "&:hover": {
              transform: "translateY(-5px)", // Optional: adds a slight lift effect on hover
            }
    },
    card: {
        height: "100%", // Make card fill container
        display: "flex",
        flexDirection: "column",
    },
    cardContent: {
        display: "flex",
        padding: "15px",
        height: "120px", // Fixed height for consistency
    },
    imageContainer: {
        width: "140px",
        height: "130px",
        flexShrink: 0,
        marginRight: "15px",
    },
    cardImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "4px",
    },
    textContainer: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    cardTitle: {
        fontSize: "1.2em",
        fontWeight: "600",
        marginBottom: "10px",
        color: "#333",
    },
    cardDescription: {
        color: "#666",
        marginBottom: "auto",
    },
    readMore: {
        color: "#1A73E8",
        fontWeight: "500",
    },
    highlightBox: {
        background: "rgba(26, 115, 232, 0.1)",
        borderRadius: "8px",
        padding: "20px",
        marginTop: "3rem",
        textAlign: "center",
    },

container: {
  width: "100%",
  maxWidth: "1600px",
  margin: "0 auto",
  padding: "0 20px",
  boxSizing: "border-box",
},

    sectionTitle: {
        fontSize: "1.8em",
        fontWeight: "700",
        margin: "15px 0 10px",
        textAlign: "left",
        color: "#333",
    },
    // Styles for flip cards section
    flipCardsContainer: {
        display: "grid",
        gap: "20px", // Increased gap
        gridTemplateColumns: "repeat(8, minmax(0, 1fr))", // Prevent overflow
        padding: "20px",
        margin: "0 auto",
        maxWidth: "100%", // Use full width
        boxSizing: "border-box", // Ensure padding is included in width
        '@media (max-width: 1400px)': {
          gridTemplateColumns: "repeat(8, minmax(0, 1fr))",
        },
        '@media (max-width: 1100px)': {
          gridTemplateColumns: "repeat(6, minmax(0, 1fr))",
        },
        '@media (max-width: 768px)': {
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        }
      },

    // Styles for summary cards section
    summaryCardContainer: {
        display: "grid",
        gap: "30px",
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        padding: "1px 0",
        marginTop: "1px",
        marginBottom: "100px"
    },
}));

export default function SectionTEDAI2024() {
    const classes = useStyles();
    const router = useRouter();

    const handleCardClick = (path) => {
        router.push(path);
    };

    const cards = [
        {
            title: "TEDAI 2024: Home Section",
            description: "Explore our comprehensive coverage of TEDAI 2024",
            image: "/img/tedai2024/gallery2/IMG_3.jpeg",
            path: "/tedai2024"
        },
        {
            title: "Day 1: Talks",
            description: "Discover the insights and innovations shared during the first day",
            image: "/img/tedai2024/home/ted-ai-header3.jpeg",
            path: "/tedai2024/tedai2024-day1-talks-summary"
        },
        {
            title: "Day 2: Panels",
            description: "Deep dive into the panel discussions and expert perspectives",
            image: "/img/tedai2024/gallery2/tedai2024_panels_header2.jpeg",
            path: "/tedai2024/tedai2024-day2-panels-summary"
        }
    ];

    return (
        <div className={classes.section}>
            <Container>
                <Typography variant="h2" className={classes.title}>
                    Check out our Coverage of TEDAI 2024!!
                </Typography>
                <Typography className={classes.emphasis}>
                    Want to hear about the latest cutting edge developments in AI?
                </Typography>
                <Typography className={classes.subtitle}>
                    On October 22-23, 2024 <strong>we attended TEDAI 2024</strong>, the world's premier AI conference in San Francisco. A huge lineup of AI pioneers and practioners delivered a day of talks at the historic Herbst Theatre, followed by a day of interactive panels at Shack15 at the San Francisco Ferry Building.
                    <br /><br />
                    Speakers included: Carlo Rovelli, Max Jaderberg, Eugenia Kudya, Noam Brown, Jakob Uszkoreit, Ethan Mollick, Pedro Domingos and many more!
                    <br /><br />
                </Typography>

                {/* Flipping Cards Section */}
                
                    <h2 className={classes.sectionTitle}>Notable Speakers</h2>
                    <div className={classes.flipCardsContainer}>
                        <CardExampleCardRotating
                            frontTitle="Carlo Rovelli"
                            frontDescription={`• Theoretical Physicist
                                • World’s 50 Top Thinkers

                                Quote: "Time is a human construct"`}
                            backTitle="Key Topics"
                            backDescription={`• Time is a human construct
        • Time may not exist in nature
        • AI's time perception differs`}
                            frontImage="/img/tedai2024/headshots/carlos-rovelli.png"
                            backImage="/img/tedai2024/gallery2/IMG_3.jpeg"
                        />
                        <CardExampleCardRotating
                            frontTitle="Jessica Coon"
                            frontDescription={`• Professor of Linguistics @ McGill University
                                • Consultant for the 2016 film 'Arrival'
                                
                                Quote: "Humans reject fake languages"`}

                            backTitle="Key Topics"
                            backDescription={`• Languages have deep structural similarities.
        • Humans reject fake languages
        • LLMs have limited language diversity.`}
                            frontImage="/img/tedai2024/headshots/jessica-coon.png"
                            backImage="/img/tedai2024/gallery2/IMG_3.jpeg"
                        />
                        <CardExampleCardRotating
                            frontTitle="Jakob Uszkoreit"
                            frontDescription={`• CEO and Co-Founder, Inceptive Nucleics
                                • Co-Author of seminal paper 'Attention is all you need'
                                
                                Quote: "Human minds are limited, AI helps"`}
                            
                            backTitle="Key Topics"
                            backDescription={`• Human minds are Limited, AI helps
     • Science should embrace noisy data
     • AI should emphasise solutions over theory`}
                            frontImage="/img/tedai2024/headshots/jakob-uszkoreit1.png"
                            backImage="/img/tedai2024/gallery2/IMG_3.jpeg"
                        />
                        <CardExampleCardRotating
                            frontTitle="Surya Ganguly"
                            frontDescription={`• Professor of Applied Physics & Associate Director of HAI Institute at Stanford University
                                
                                Quote: "Human brains have predictive energy allocation"`}
                            
                            backTitle="Key Topics"
                            backDescription={`• Humans are efficient learning machines
      • AI should try match biology's efficiency
      • Human brains have predictive energy allocation.`}
                            frontImage="/img/tedai2024/headshots/surya-ganguly.png"
                            backImage="/img/tedai2024/gallery2/IMG_3.jpeg"
                        />
                        <CardExampleCardRotating
                            frontTitle="Max Jaderberg"
                            frontDescription=
                            {`• Chief AI Officer, Isomorphic Labs
                                • Co-Developer of AlphaStar, Grandmaster Starcraft II agent
                                
                                Quote: "We've saved 1 billion years of research"`}
                            
                            backTitle="Key Topics"
                            backDescription={`• AlphaFold is revolutionising drug design
      • Saved 1 billion years of research
      • Personalized medicine is coming, soon`}
                            frontImage="/img/tedai2024/headshots/max-jaderberg1.png"
                            backImage="/img/tedai2024/gallery2/IMG_3.jpeg"
                        />
                        <CardExampleCardRotating
                            frontTitle="Guillaume Verdon"
                            frontDescription=
                            {`• Founder & CEO, Extropic
                                • Creator of TensorFlow Quantum
                                
                                Quote: "Thermo chips are the new AI hardware"`}
                                
                            backTitle="Key Topics"
                            backDescription={`• AI should mimic brain's efficiency
      • Mesoscale is the key to efficient AI
      • Thermo chips are the new AI hardware.`}
                            frontImage="/img/tedai2024/headshots/guillaume-verdon.png"
                            backImage="/img/tedai2024/gallery2/IMG_3.jpeg"
                        />
                                                <CardExampleCardRotating
                            frontTitle="Ethan Mollick"
                            frontDescription=
                            {`• Co-Director, Generative AI Lab, Wharton School of the University of Pennsylvania
                                
                                Quote: "Use AI for growth, not just cost-cutting"`}
                                
                            backTitle="Key Topics"
                            backDescription={`• AI should mimic brain's efficiency
      • Mesoscale is the key to efficient AI
      • Thermo chips are the new AI hardware.`}
                            frontImage="/img/tedai2024/headshots/ethan-mollick.jpeg"
                            backImage="/img/tedai2024/gallery2/IMG_3.jpeg"
                        />
                                                <CardExampleCardRotating
                            frontTitle="Pedro Domingos"
                            frontDescription=
                            {`• Professor Emeritus of Computer Science and Engineering, University of Washington
                                
                                Quote: "In time the floor of senate will be like the stock exchange"`}
                            
                            backTitle="Key Topics"
                            backDescription={`• AI should mimic brain's efficiency
      • Mesoscale is the key to efficient AI
      • Thermo chips are the new AI hardware.`}
                            frontImage="/img/tedai2024/headshots/pedro-domingos.jpeg"
                            backImage="/img/tedai2024/gallery2/IMG_3.jpeg"
                        />
                    </div>
                
                <p><br /><br /></p>
                <h2 className={classes.sectionTitle}>Explore the conference</h2>

                <div className={classes.summaryCardContainer}>
                    
                    {cards.map((card, index) => (
                        <div
                            key={index}
                            className={classes.clickableCard}
                            onClick={() => handleCardClick(card.path)}
                        >
                            <Card className={classes.card}>
                                <div className={classes.cardContent}>
                                    <div className={classes.imageContainer}>
                                        <img
                                            src={card.image}
                                            alt={card.title}
                                            className={classes.cardImage}
                                        />
                                    </div>
                                    <div className={classes.textContainer}>
                                        <h4 className={classes.cardTitle}>{card.title}</h4>
                                        <p className={classes.cardDescription}>{card.description}</p>
                                        <CardFooter>
                                            <span className={classes.readMore}>Read More</span>
                                        </CardFooter>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ))}
                </div>

                <div className={classes.highlightBox}>
                    <Typography variant="h6" gutterBottom>
                        🎉 On October 22-23, 2024 we attended TEDAI 2024 in San Francisco
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.ctaButton}
                        href="/tedai2024"
                    >
                        See what we learned at TEDAI 2024
                    </Button>
                </div>
            </Container>
        </div>
    );
}