import React from "react";
import makeStyles from "@mui/styles/makeStyles";
// @mui/icons-material
import Chat from "@mui/icons-material/Chat";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import Fingerprint from "@mui/icons-material/Fingerprint";
import GroupWork from "@mui/icons-material/GroupWork";
import Airplay from "@mui/icons-material/Airplay";
import LocationOn from "@mui/icons-material/LocationOn";
import Extension from "@mui/icons-material/Extension";
import ChildFriendly from "@mui/icons-material/ChildFriendly";
import WatchLater from "@mui/icons-material/WatchLater";
import Code from "@mui/icons-material/Code";
import FormatPaint from "@mui/icons-material/FormatPaint";
import Dashboard from "@mui/icons-material/Dashboard";
import ViewCarousel from "@mui/icons-material/ViewCarousel";
import AccessTime from "@mui/icons-material/AccessTime";
import AttachMoney from "@mui/icons-material/AttachMoney";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoAreaTribalism from "components/InfoArea/InfoAreaTribalism.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";
import Favorite from "@mui/icons-material/Favorite";
import Quote from "components/Typography/Quote.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Image from "next/image";

import featuresStyle from "styles/jss/nextjs-material-kit-pro/pages/sectionsSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div
        className="clients"
        //className={classes.team + " " + classes.section}
        //style={{ backgroundImage: "url('/img/bgs/bg1.jpg')"}}
        //style={{ backgroundColor: "lightgrey"}}
        style={{}}
      >
        {/* Feature 4 START */}
        <div className={classes.container}>
          <h2 className={classes.title}>Advanced Journey Analysis using D3</h2>
          <h4>Looking for ways to visualise your website journeys better? GA4 doesnt do this. Most tools dont. This is a really innovative way to help answer that perennial question: how are people using my website?</h4>
          <h4 className={classes.title}>
            <i>
              (Click the image below to see how people were using the Tribalism website in 2023
              )
            </i>
          </h4>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <a href="/our-work/sunburst-journey-analysis">
            <img
                src="/img/home-page/sunburst-diagram.png"
                style={{ maxWidth: "100%", height: "auto", display: "block" }}
              ></img>
              </a>
            </GridItem>

          </GridContainer>
        </div>

        {/* Feature 4 END */}
      </div>
    </div>
  );
}
