import React from "react";
import dynamic from "next/dynamic";
//import useInView from "react-cool-inview"
import { useInView } from "react-cool-inview";
import Image from "next/image";
import makeStyles from '@mui/styles/makeStyles';
// @mui/icons-material
import FormatQuote from "@mui/icons-material/FormatQuote";
// import Star from "@mui/icons-material/Star";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Card from "components/Card/Card.js";
const Card = dynamic(() => import("components/Card/Card.js"), {
  loading: () => <p>...</p>,
});
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
// import Muted from "components/Typography/Muted.js";
// import Warning from "components/Typography/Warning.js";

import testimonialsStyle from "styles/jss/nextjs-material-kit-pro/pages/sectionsSections/testimonialsStyle.js";

const useStyles = makeStyles(testimonialsStyle);

export default function SectionTestimonials({ ...rest }) {
  const { observe, inView } = useInView({
    onEnter: ({ unobserve }) => unobserve(), // only run once
  });

  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <div className="cd-section" {...rest}>
      {/* Testimonials 1 START */}
      <div className={classes.testimonials + " " + classes.sectionImage}>
        <Image
          layout="fill"
          objectFit="cover"
          src={"/img/examples/city-sm.webp"}
          alt=".."
        />
        <div className={classes.container} ref={observe}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Our Clients Love Us</h2>
              <h5 className={classes.description}>
                You need more information? Check what other people are saying
                about our services. They are very happy with our work.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              {inView && (
                <Card testimonial className={classes.card1}>
                  <div className={classes.icon}>
                    <FormatQuote />
                  </div>
                  <CardBody>
                    <h5 className={classes.cardDescription}>
                      Tribalism have been great to work with so far and were
                      very flexible and understanding when helping us work
                      through the complexities of reporting over 200 different
                      websites. Implementing roll-up reporting was a great win
                      to give us an overview of all stores in one report.
                    </h5>
                  </CardBody>
                  <CardFooter testimonial>
                    <h4 className={classes.cardTitle}>Rose Treacy</h4>
                    <h6 className={classes.cardCategory}>
                      Ecommerce Director,Universal Music Australia
                    </h6>
                    <CardAvatar testimonial testimonialFooter>
                      <img src="/img/faces/rose-small.webp" alt="..." />
                    </CardAvatar>
                  </CardFooter>
                </Card>
              )}
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              {inView && (
                <Card testimonial className={classes.card1}>
                  <div className={classes.icon}>
                    <FormatQuote />
                  </div>
                  <CardBody>
                    <h5 className={classes.cardDescription}>
                      Tribalism has been nothing but a pleasure to work with
                      across two seperate companies. The Tribalism team have
                      thorough technical knowledge and know how to communicate
                      it to those with less technical know-how. Highly recommend
                      them!
                    </h5>
                  </CardBody>
                  <CardFooter testimonial>
                    <h4 className={classes.cardTitle}>Rebecca Del Rio</h4>
                    <h6 className={classes.cardCategory}>
                      Head of Marketing and Development, Bizcap Australia
                    </h6>
                    <CardAvatar testimonial testimonialFooter>
                      <img src="/img/faces/rebecca-small.webp" alt="..." />
                    </CardAvatar>
                  </CardFooter>
                </Card>
              )}
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              {inView && (
                <Card testimonial className={classes.card1}>
                  <div className={classes.icon}>
                    <FormatQuote />
                  </div>
                  <CardBody>
                    <h5 className={classes.cardDescription}>
                      We worked closely with Tribalism to launch our startup and
                      have applied market led research towards a new website and
                      data led advertising. We look forward to driving traction
                      and growth with Scott and team and can't wait to keep
                      enhancing our digital marketing plans.
                    </h5>
                  </CardBody>
                  <CardFooter testimonial>
                    <h4 className={classes.cardTitle}>Courtney Smith</h4>
                    <h6 className={classes.cardCategory}>
                      Director, <br /> Quallogi Australia
                    </h6>
                    <CardAvatar testimonial testimonialFooter>
                      <img src="/img/faces/courtney-small.webp" alt="..." />
                    </CardAvatar>
                  </CardFooter>
                </Card>
              )}
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Testimonials 1 END */}
    </div>
  );
}
