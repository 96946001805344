import React from "react";
import makeStyles from "@mui/styles/makeStyles";


const useStyles = makeStyles((theme) => ({
  rotatingCardContainer: {
    perspective: "1000px",
    position: "relative",
    width: "100%", // Take full width of grid cell
    margin: "0",
    padding: "0",
    boxSizing: "border-box",
  },
  cardRotate: {
    transformStyle: "preserve-3d",
    position: "relative",
    transformOrigin: "center center",
    background: "transparent",
    boxShadow: "none",
    "&:hover": {
      "& $back": {
        transform: "rotateY(0deg)",
        zIndex: "5",
      },
      "& $front": {
        transform: "rotateY(180deg)",
        zIndex: "1",
      },
    },
  },
  front: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    transformStyle: "preserve-3d",
    transition: "transform 0.6s ease-out",
    transform: "rotateY(0deg)",
    zIndex: "2",
    background: "transparent",
    boxShadow: "none",
  },
  back: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    transformStyle: "preserve-3d",
    transition: "transform 0.6s ease-out",
    transform: "rotateY(-180deg)",
    zIndex: "1",
    background: "transparent",
    boxShadow: "none",
  },
  wrapperBackground: {
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    width: "100%",
    position: "absolute",
    borderRadius: "6px",
    overflow: "hidden",
    zIndex: "1",
    '&:after': {
      background: "rgba(0, 0, 0, 0.4)", // Consistent overlay for entire card
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      position: "absolute",
      top: "0",
      left: "0",
      zIndex: "1"
    }
  },
  cardBodyRotate: {
    padding: "5px",
    position: "relative",
    zIndex: "2",
    height: "100%",
    width: "100%",
    background: "transparent",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Center content vertically
    alignItems: "center", // Center content horizontally
  },
  
  cardCategoryWhite: {
    fontSize: "0.7em",
    marginTop: "2px",
    marginBottom: "2px",
    color: "#fff",
    textAlign: "center",
    position: "relative", // Ensure text is above overlay
    zIndex: "2",
  },
  
  cardTitleWhite: {
    fontSize: "0.8em",
    marginTop: "2px",
    marginBottom: "2px",
    color: "#fff",
    fontWeight: "500",
    textAlign: "center",
    textShadow: "1px 1px 2px rgba(0,0,0,0.7)", // Add text shadow for readability
  },
  
  cardDescriptionWhite: {
    fontSize: "0.6em",
    lineHeight: "1.2",
    marginTop: "2px",
    color: "#fff",
    textAlign: "center",
    position: "relative", // Ensure text is above overlay
    zIndex: "2",
  },

  cardDescriptionWhiteBack: {
    whiteSpace: "pre-line",
    textAlign: "left",
    paddingLeft: "5px",
    color: "#FFFFFF",
    opacity: "1",
    fontWeight: "500",
    fontSize: "0.6em",
    lineHeight: "1.2",
    position: "relative", // Ensure text is above overlay
    zIndex: "2",
  }
}));

export default function CardExampleCardRotating({
  frontTitle,
  frontDescription,
  backTitle,
  backDescription,
  frontImage,
  backImage
}) {
  React.useEffect(() => {
    const addStylesForRotatingCards = () => {
      var rotatingCards = document.getElementsByClassName(classes.cardRotate);
      for (let i = 0; i < rotatingCards.length; i++) {
        var rotatingCard = rotatingCards[i];
        var cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
        var cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
        var cardWidth = rotatingCard.parentElement.offsetWidth;
        var cardHeight = cardWidth; // Keep it square
        rotatingCard.style.height = cardHeight + "px";
        cardFront.style.height = cardHeight + "px";
        cardFront.style.width = cardWidth + "px";
        cardBack.style.height = cardHeight + "px";
        cardBack.style.width = cardWidth + "px";
      }
    };
    
    addStylesForRotatingCards();
    window.addEventListener("resize", addStylesForRotatingCards);
    return () => window.removeEventListener("resize", addStylesForRotatingCards);
  }, []);

  const classes = useStyles();
  
  return (
    <div className={classes.rotatingCardContainer}>
      <div className={classes.cardRotate}>
        <div
          className={`${classes.front} ${classes.wrapperBackground}`}
          style={{
            backgroundImage: `url('${frontImage || '/img/examples/card-blog5.jpg'}')`
          }}
        >
          <div className={classes.cardBodyRotate}>
            <h5 className={classes.cardTitleWhite}>{frontTitle}</h5>
          </div>
        </div>
        <div
          className={`${classes.back} ${classes.wrapperBackground}`}
          style={{
            backgroundImage: `url('${backImage || frontImage || '/img/examples/card-blog5.jpg'}')`
          }}
        >
          <div className={classes.cardBodyRotate}>
            <p className={classes.cardDescriptionWhiteBack}>
              {frontDescription}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}