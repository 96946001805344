import React from "react";

import makeStyles from "@mui/styles/makeStyles";
// @mui/icons-material
// core components
import Header from "components/Header/HeaderTribalism.js";
import HeaderLinks from "components/Header/HeaderLinksTribalism.js";
// import Parallax from "components/Parallax/Parallax.js";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// sections of this Page
import SectionHeadersTribalism from "pages-sections/home-page/SectionHeadersTribalism.js";
//import SectionFeaturesTribalism from "pages-sections/sections-page/SectionFeaturesTribalism.js";

//importing page sections
import Section1Welcome from "pages-sections/home-page/Section1Welcome.js";
import Section3Team from "pages-sections/home-page/Section3Team.js";
import Section4Clients from "pages-sections/home-page/Section4Clients.js";
import Section5ScottMessage from "pages-sections/home-page/Section5ScottMessage.js";
import Section9News from "pages-sections/home-page/Section9News.js";
import Section6Blog from "pages-sections/home-page/Section6Blog.js";
import Section7Testimonials from "pages-sections/home-page/Section7Testimonials.js";
import Section55Services from "pages-sections/home-page/Section55Services.js";
import Footer from "components/Footer/TribalismFooter.js";
import SectionProjects from "pages-sections/home-page/SectionProjects2.js";
import Section8Contacts from "pages-sections/home-page/Section8Contacts.js";
import Section4ClientsLandingPage from "pages-sections/home-page/Section4ClientsLandingPage.js";
import SectionCertifications from "pages-sections/home-page/SectionCertifications.js";
import Section10sunburst from "pages-sections/home-page/Section10sunburst.js";
import SectionTEDAI2024 from "pages-sections/home-page/SectionTEDAI2024.js";

// const Section1Welcome = dynamic(()=>import("pages-sections/home-page/Section1Welcome.js"))
// const Section3Team = dynamic(()=>import("pages-sections/home-page/Section3Team.js"))
// const Section4Clients = dynamic(()=>import("pages-sections/home-page/Section4Clients.js"))
// const Section5ScottMessage = dynamic(()=>import("pages-sections/home-page/Section5ScottMessage.js"))
// const Section6Blog = dynamic(()=>import("pages-sections/home-page/Section6Blog.js"))
// const Section7Testimonials = dynamic(()=>import("pages-sections/home-page/Section7Testimonials.js"))
// const Section55Services = dynamic(()=>import("pages-sections/home-page/Section55Services.js"))
// const Footer = dynamic(()=>import("components/Footer/TribalismFooter.js"))
// const SectionProjects = dynamic(()=>import("pages-sections/home-page/SectionProjects2.js"))
// const Section8Contacts = dynamic(()=>import("pages-sections/home-page/Section8Contacts.js"))

// import Section2Bizcap from "pages-sections/home-page/Section2Bizcap.js";
//import Section51ScottMessage from "pages-sections/home-page/Section51ScottMessage.js"
//import SectionCaseStudies from "pages-sections/sections-page/SectionCaseStudies.js";
//import SectionBlogs from "pages-sections/sections-page/SectionBlogs.js";
//import SectionTeams from "pages-sections/sections-page/SectionTeams.js";
//import SectionProjectsTribalism from "pages-sections/sections-page/SectionProjectsTribalism.js";
//import SectionPricing from "pages-sections/sections-page/SectionPricing.js";
//import SectionProjects from "pages-sections/sections-page/SectionProjects.js";
// import SectionTestimonials from "pages-sections/sections-page/SectionTestimonials.js";
// import SectionFeatures from "pages-sections/sections-page/SectionFeatures.js";

import sectionsPageStyle from "styles/jss/nextjs-material-kit-pro/pages/sectionsPageStyle.js";
//import sectionsPageStyle from "styles/jss/nextjs-material-kit-pro/pages/aboutUsStyle.js";

//import bannerStyles from "styles/jss/nextjs-material-kit-pro/components/bannerStyle.js";
import Banner from "components/Banner/Banner.js";
//import Header from "components/Header/HeaderTribalism2.js";
import BannerNov from "/pages-sections/components/BannerNov.js";

const useStyles = makeStyles(sectionsPageStyle);

export default function SectionsPage() {
  React.useEffect(() => {
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (window.location.href.lastIndexOf("#") > 0) {
      document.getElementById(href).scrollIntoView();
    }
    window.addEventListener("scroll", updateView);
    updateView();
    return function cleanup() {
      window.removeEventListener("scroll", updateView);
    };
  });
  const updateView = () => {
    var contentSections = document.getElementsByClassName("cd-section");
    var navigationItems = document
      .getElementById("cd-vertical-nav")
      .getElementsByTagName("a");

    for (let i = 0; i < contentSections.length; i++) {
      var activeSection =
        parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
      if (
        contentSections[i].offsetTop - window.innerHeight / 2 <
          window.pageYOffset &&
        contentSections[i].offsetTop +
          contentSections[i].scrollHeight -
          window.innerHeight / 2 >
          window.pageYOffset
      ) {
        navigationItems[activeSection].classList.add("is-selected");
      } else {
        navigationItems[activeSection].classList.remove("is-selected");
      }
    }
  };
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  const smoothScroll = (target) => {
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  const classes = useStyles();
  return (
    <div>
      <Header
        brand="/img/clients/TribalismLogoRev.webp"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        title="Tribalism: Australian Data & Analytics Agency with a Difference"
        image="/img/home-page/home-image.png"
        url="https://www.tribalism.com.au"
        type="article"
        description="We are a Data & Analytics Agency based in Melbourne, Australia. We cover analytics, search engine marketing and web design. We help your digital platforms perfrorm better!"
        changeColorOnScroll={{
          height: 400,
          color: "info",
        }}
      />
      <div className={classes.main}>
        <SectionHeadersTribalism id="headers" />
        <Section1Welcome id="welcome" />

        <SectionTEDAI2024 id="tedai2024" />  {/* Add this line */}

        <SectionProjects id="case-studies" />
        <Section10sunburst id="sunburst" />

        <Section4ClientsLandingPage id="clients" />

        <Section6Blog id="blog" />
        <Section8Contacts id="contact" />
      </div>
      <nav id="cd-vertical-nav">
        <ul>
          <li>
            <a
              href="#headers"
              data-number="1"
              className="is-selected"
              onClick={(e) => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("headers");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Headers</span>
            </a>
          </li>
          <li>
            <a
              href="#welcome"
              data-number="2"
              className="is-selected"
              onClick={(e) => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("welcome");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Welcome</span>
            </a>
          </li>
          <li>
            <a
              href="#case-studies"
              data-number="3"
              className=""
              onClick={(e) => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("case-studies");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Case Studies</span>
            </a>
          </li>
          <li>
            <a
              href="#sunburst"
              data-number="4"
              className=""
              onClick={(e) => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("sunburst");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Sunburst</span>
            </a>
          </li>
          <li>
            <a
              href="#clients"
              data-number="5"
              className=""
              onClick={(e) => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("clients");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Clients</span>
            </a>
          </li>
          <li>
            <a
              href="#blog"
              data-number="6"
              className=""
              onClick={(e) => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("blog");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Blog</span>
            </a>
          </li>
          <li>
            <a
              href="#contact"
              data-number="7"
              className=""
              onClick={(e) => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("contact");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Contact</span>
            </a>
          </li>
        </ul>
      </nav>
      <Footer />
    </div>
  );
}
